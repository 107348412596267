<template>
  <div class="container">
    <nav-bar :header-title="title" :show-back="true" :header-back="appBack" />
    <div class="">

      <!-- 日期选择区 -->
      <div class="date-box row-between-center">
        <div :class="['date-item', 'col-start-center', item.date === params.date ? 'active' : '']" v-for="item in dateArr" :key="item.week" @click="dateClick(item)">
          <p :class="['f22']">{{ item.week }}</p>
          <div :class="['f40', 'BebasNeueBold', 'row-center-center']">{{ item.day }}</div>
        </div>
      </div>

      <!-- 课程 -->
      <div class="course-box">

        <div class="card flex" @click="goCourseDetail(item.mouldId)" v-for="item in courseList" :key="item.mouldId">
          <div class="left">
            <img class="avator flex-none" :src="item.teacherImg" />
          </div>
          <div class="right row-between-end flex-auto">
            <div>
              <div class="info">
                <div class="course-name f24 fw6 ellipsis">{{ item.courseName }}</div>
                <div class="course-info f22 row-start-center ellipsis">
                  <div class="date fw6">{{ item.weekStr }} {{ item.startTime }}-{{ item.endTime }}</div>
                  <div class="name">{{ item.teacherName }}</div>
                </div>
              </div>

              <div class="row-start-center price-box">
                <div class="price row-start-end BebasNeueBold">
                  <span class="t1 f20">¥</span>
                  <span class="t2 f36">{{ item.costMoney }}</span>
                </div>
                <div v-if="item.courseStrengthLabel" class="tabs f20 grey row-center-center">{{ item.courseStrengthLabel }}</div>
                <div v-if="item.equityCardDiscount" class="tabs f20 red row-center-center">{{ item.equityCardDiscountText }}</div>
              </div>
            </div>

            <div class="bottom">
              <div :style="{ 'color': item.btn.fontColor, 'background-color': item.btn.buttonColor }" class="right-btn col-center-center f24">
                <div class="v1 bold">{{ item.btn.btnShowMsg }}</div>
                <div class="v2">已约{{ item.haveNum }}/{{ item.maxNum }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- 空 -->
      <div v-if="courseList.length === 0" class="empty col-start-center">
        <img class="empty-img" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/selfGroupClass/emptyList.png" alt="" />
        <div class="empty-text">鹿团团正在努力排课中，敬请期待</div>
      </div>

    </div>

  </div>
</template>

<script>

import userMixin from '@/mixin/userMixin'
import { closeWindow, initBack, gotoCourseDetail } from '@/lib/appMethod'
import navBar from '@/components/nav-bar-202210/index'

export default {
  mixins: [userMixin],
  components: {
    navBar,

  },
  data() {
    return {

      params: {

      },
      // 标题
      title: '',

      // 日期
      dateArr: [], // 日期列表
      dateNow: '', // 当前选中的日期

      choseItem: {},
      // 课程
      courseList: [],
    };
  },
  async created() {
    this.$toast.loading({
      duration: 1000,
      forbidClick: true,
      loadingType: 'spinner'
    })
    await this.$getAllInfo(['userId', 'local'])
    initBack()

    this.params = {
      courseId: this.$route.query.courseId,
      date: '',
      venueId: this.$route.query.venueId,
      userId: this.userId,
    }

    await this.getDateArr()
    this.getData()

  },
  methods: {
    appBack: closeWindow,

    // 获取日期
    getDateArr() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.baseURLApp + '/scheduleLesson/lessonDate', this.params)
          .then((res) => {
            this.dateArr = res.data
            this.params.date = res.data[0].date
            resolve()
          })
      })
    },
    // 获取建议列表
    getData() {
      this.$axios.post(this.baseURLApp + '/scheduleLesson/lessonListWhenVenueCourseDetail', this.params).then((res) => {
        this.courseList = res.data
        this.$toast.clear()
      }).catch(() => {
        this.$toast.clear()
      })
    },

    // 去课程详情
    goCourseDetail(id) {
      gotoCourseDetail(id)
    },

    // 选择日期
    dateClick(item) {
      if (this.loadingShow) {
        return;
      }
      this.params.date = item.date;
      this.getData();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background-color: #F5F5F5;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }


  .date-box {
    padding: 32px 32px;
    background-color: #fff;

    .date-item {
      p {
        color: #9AA1A9;
      }

      div {
        width: 64px;
        height: 64px;
        background: transparent;
        border-radius: 8px;
        margin: 8px 0 0;
        padding: 6px 0 0;
      }

      &.active {
        p {
          color: #3C454E;
        }

        div {
          background: #3C454E;
          color: #fff;

        }
      }
    }
  }


  .course-box {

    .card {
      padding: 48px 32px;
      border-radius: 8px;
      background-color: #fff;
      color: #242831;
      margin: 0 0 16px;
      border: 2px solid #EEEEEE;

      .right {
        margin: 0 0 0 24px;
      }
    }

    .avator {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }

    .course-name {
      margin: 0 0 16px;
    }

    .course-name,
    .course-info {
      max-width: 370px;
    }

    .date {
      line-height: 1;
      padding: 0 16px 0 0;
    }

    .name {
      border-left: 2px solid #ddd;
      line-height: 1;
      padding: 0 0 0 16px;
    }

    .price-box {
      margin: 20px 0 0;

      .tabs {
        height: 32px;
        border-radius: 4px;
        line-height: 22px;
        color: #267dff;
        margin: 0 16px 0 0;
        max-width: 240px;
      }

      .tabs.red {
        padding: 0 8px;
        color: #f03c18;
        border: 1px solid #f03c18;
      }

      .tabs.grey {
        padding: 0 8px;
        color: #242831;
        border: 1px solid #9aa1a9;
      }

      .tabs.black {
        color: #242831;
      }

      .price {
        color: #F03C18;
        margin: 0 16px 0 0;

        .t2 {
          position: relative;
          top: 4px;
          margin: 0 0 0 4px;
        }
      }
    }

    .bottom {
      margin-top: 24px;

      .right-btn {
        width: 120px;
        height: 84px;
        border-radius: 8px;

        &.active {
          background: #ffde00;
          color: #242831;
        }

        .v1 {
          font-size: 24px;
          line-height: 24px;
        }

        .v2 {
          line-height: 20px;
          font-size: 18px;
          margin-top: 12px;
        }

      }
    }


  }

  .empty {
    margin-top: 200px;

    .empty-img {
      width: 140px;
      display: inline-block;
    }

    .empty-text {
      margin: 32px 0 0;
    }
  }


}
</style>
